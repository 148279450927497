import axios from "axios";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_USER_MESSAGE = "UPDATE_USER_MESSAGE";

export const textQueryAction = (data) => {
  console.log(data);
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_USER_MESSAGE, data: data });
    //console.log("API URL:", process.env.REACT_APP_IP_ADRRESS_API_KEY);
    const response = await axios.post(
      process.env.REACT_APP_SERVER_URL + "/text_query",
      {
        text: data.text,
        userId: "tej-03449kjl",
      }
    );

    console.log("response from dialogflow", response);
    return dispatch({ type: UPDATE_MESSAGE, data: response.data });
  };
};

export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const clearMessages = () => {
  return {
    type: CLEAR_MESSAGES,
  };
};
