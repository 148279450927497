import React, { useState } from "react";
import { ButtonPrimary } from "../buttons/Buttons";
import { Modal } from "antd";

const QRModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="">
      <ButtonPrimary text={"Download"} fun={showModal} />
      <Modal
        title=" "
        open={isModalOpen}
        onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="p-10 flex flex-col gap-1 text-center ">
          <span className="text-2xl font-bold">Scan QR code</span>
          <span className="text-base font-semibold">
            scan this QR code download <span className="text-cardBg2">SkoolBus app</span>
          </span>
          <img src="/assets/QRcode/Qr.svg" alt="qr" />
        </div>
      </Modal>
    </div>
  );
};

export { QRModal };
