import React, { useState, useEffect } from 'react';

const ChatbotAnswer = ({ message }) => {
  const [displayedMessage, setDisplayedMessage] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const messageLength = message.length;
    const typingSpeed = 50; // Adjust the typing speed as needed

    const typingInterval = setInterval(() => {
      if (currentIndex < messageLength) {
        setDisplayedMessage(message.substring(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);
      } else {
        clearInterval(typingInterval);
      }
    }, typingSpeed);

    return () => {
      clearInterval(typingInterval);
    };
  }, [message, currentIndex]);

  return (
    <div className="w-full flex gap-4 p-5">
      <img
        src="./assets/icons/skoolbuslogo.svg"
        alt="chatbot"
        className=""
      />
      <h1 className="text-base font-normal text-black">{displayedMessage}</h1>
    </div>
  );
};

export default ChatbotAnswer;
