import React from "react";
import { useSelector } from "react-redux";
import ChatbotQuestion from "./ChatbotQuestion";
import ChatbotAnswer from "./ChatbotAnswer";

const ChatbotMessages = () => {
  const messages = useSelector((state) => state.chatbot.messages);
  console.log("message", messages);

  const displayMessage = (message, index) => {
    console.log("message", message);
    if (message.speak === "user") {
      return <ChatbotQuestion message={message.text} key={index} />;
    } else if (message.speak === "bot") {
      return <ChatbotAnswer message={message.text} key={index} />;
    }
  };
  return (
    <div className="w-full h-full overflow-x-hidden chatbot-scrollbar">
      {messages.map((message, index) => {
        return displayMessage(message, index);
      })}
    </div>
  );
};

export default ChatbotMessages;
