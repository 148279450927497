import React, { useEffect, useState } from "react";
import {
  ValidateEmail,
  ValidateFirstName,
  ValidateLastName,
  ValidateMessage,
  ValidatePhone,
} from "../../validation";
import InlineError from "../../validation/InlineError";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SendEmail } from "../../API";


const FormBody = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const [firstNameError, setFirstNameError] = useState();
  const [lastNameError, setLastNameError] = useState();
  const [emailError, setEmailError] = useState();
  const [messageError, setMessageError] = useState();
  const [phoneError, setPhoneError] = useState();

  const [buttonLoading, setButtonLoading] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [send, setSend] = useState();
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    ValidateFirstName({ firstName, setFirstNameError });
    ValidateLastName({ lastName, setLastNameError });
    ValidateEmail({ email, setEmailError });
    ValidateMessage({ message, setMessageError });
    ValidatePhone({ mobileNumber, setPhoneError });

    if (send) {
      //toast.success(send.msg);
      setFirstName("");
      setLastName("");
      setEmail("");
      setMessage("");
      setMobileNumber("");
      //setCountries("");
      setSend(false);
      setIsSent(true);
      setTimeout(() => setIsSent(false), 5000);
    }
  }, [firstName, lastName, email, message, mobileNumber, send]);

  console.log(firstNameError);

  const submitHandler = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    if (
      !firstNameError ||
      !lastNameError ||
      !emailError ||
      !messageError ||
      !phoneError
    ) {
      console.log("Sending email");
      SendEmail({
        firstName,
        lastName,
        message,
        email,
        mobileNumber,
        setSend,
      }).then(() => {
        setButtonLoading(false);
        console.log(SendEmail);
        console.log("Email sent");
      });
    }
    toast.success("Email sent successfully !");
  };

  return (
    <>
      <div className=" w-full bg-cardBg lg:pt-24lg:pb-0  lg:px-40 p-10">
        <div className="w-full h-full bg-cardBg flex flex-col justify-center items-center gap-8 p-4 sm:p-10">
          <div className="NeueBold text-2xl sm:text-5xl font-semibold">
            Contact Us
          </div>
          <div className="Neue text-base lg:text-lg font-medium text-center w-full max-w-[600px]">
            Have a question or need assistance?
            <br /> Fill out the form below, and our dedicated support team will
            promptly address your concerns.
          </div>
          <form
            className="w-full max-w-[700px] flex flex-col gap-4 sm:p-10"
            onSubmit={submitHandler}
          >
            <div className="flex flex-col sm:flex-row gap-4  w-full">
              <div className="flex flex-col gap-1 w-full">
                <span className="Neue">First Name</span>
                <input
                  type="text"
                  placeholder="John"
                  className="bg-inputTypebg w-full h-12 p-3 placeholder-[#2D2B2B66] outline-none Neue border-b-2 border-b-[#f0eaf3]"
                  required
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {firstNameError && <InlineError error={firstNameError} />}
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="Neue">Last Name</span>
                <input
                  type="text"
                  placeholder="Doe"
                  className="bg-inputTypebg w-full h-12 p-3 placeholder-[#2D2B2B66] outline-none Neue border-b-2 border-b-[#f0eaf3]"
                  required
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {lastNameError && <InlineError error={lastNameError} />}
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-4  w-full">
              <div className="flex flex-col gap-1 w-full">
                <span className="Neue">Mobile Number</span>
                <input
                  type="text"
                  placeholder="+94 678 956 7945"
                  className="bg-inputTypebg w-full h-12 p-3 placeholder-[#2D2B2B66] outline-none Neue border-b-2 border-b-[#f0eaf3]"
                  required
                  name="mobileNumber"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
                {phoneError && <InlineError error={phoneError} />}
              </div>
            </div>
            <div className="flex flex-col gap-1 w-full">
              <span className="Neue">Email</span>
              <input
                type="email"
                placeholder="john@example.com"
                className="bg-inputTypebg w-full h-12 p-3 placeholder-[#2D2B2B66] outline-none Neue border-b-2 border-b-[#f0eaf3]"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailError && <InlineError error={emailError} />}
            </div>
            <div className="flex flex-col gap-1 w-full">
              <span className="Neue">Message</span>
              <textarea
                type="email"
                placeholder="Message"
                className="bg-inputTypebg w-full max-h-[95px] h-full p-3 placeholder-[#2D2B2B66] outline-none Neue border-b-2 border-b-[#f0eaf3]"
                draggable="false"
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {messageError && <InlineError error={messageError} />}
            </div>
            <div className="flex flex-row gap-1 w-full justify-end it">
              <button
                type="submit"
                // value="Submit"
                disabled={buttonLoading && true}
                className="bg-white w-full h-12 p-3 placeholder-[#2D2B2B66] outline-none NeueBold text-main border border-main rounded-md max-w-[134px]  hover:border-b-main hover:border-b-2 hover:rounded-md hover:bg-main  hover:text-white cursor-pointer"
              >
                {buttonLoading ? "Loading.." : "SUBMIT"}
              </button>
              <ToastContainer />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormBody;
