import React, { useState } from "react";
import { BiCollapse } from "react-icons/bi";
import Chatbot from "./Chatbot";
import { useDispatch } from "react-redux";
import { clearMessages } from "../../store/actions/ChatbotActions";
import { MdSupportAgent } from "react-icons/md";

const ChatbotIcon = () => {
  const [showChatbotBody, setShowChatbotBody] = useState(false);

  const dispatch = useDispatch();

  const handleRefreshClick = () => {
    dispatch(clearMessages());
    toggleChatbotBody();
  };

  const toggleChatbotBody = () => {
    setShowChatbotBody(!showChatbotBody);
  };
  return (
    <div>
      <div
        className="fixed bottom-2 right-5 bg-main text-white border-none rounded-[40px] w-12 h-12 flex items-center justify-center cursor-pointer z-10"
        onClick={handleRefreshClick}
      >
        {showChatbotBody ? (
          <BiCollapse onClick={handleRefreshClick} />
        ) : (
          <MdSupportAgent className="w-6 h-6" />
        )}
      </div>
      {showChatbotBody && <Chatbot />}
    </div>
  );
};

export default ChatbotIcon;
