import React from "react";

const ChatbotDefualtQuestionButton = ({ Question, onClick }) => {
  return (
    <div
      className=" w-full flex flex-col items-start gap-[4px] p-[12px] relative rounded-[12px] border border-solid border-[#45115f] cursor-pointer hover:scale-95 duration-300 ease-in group hover:bg-[#45115f]"
      onClick={onClick}
    >
      <div className="relative self-stretch mt-[-1.00px] [font-family:'Manrope-SemiBold',Helvetica] font-semibold text-[#45115f] text-[15px] group-hover:text-[#eee7f0] text-center tracking-[0] leading-[24px]">
        {Question}
      </div>
    </div>
  );
};

export default ChatbotDefualtQuestionButton;
