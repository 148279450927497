import React, { useEffect, useState } from "react";
import {
  AdvancedTrackingSystem,
  Communication,
  RealTimeUpdates,
  YourNeeds,
} from "../../constants/Data";
import { LeftsideSection, RightsideSection } from "../sections/Sections";
import { MobilePhone } from "../MobilePhone/MobilePhone";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

const CardSection = () => {
  const [showMobilePhone, setShowMobilePhone] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const screenHeight = window.innerHeight;

      const threshold = 1.3 * screenHeight;
      const threshold2 = 4.5 * screenHeight;

      setShowMobilePhone(
        scrollPosition >= threshold && scrollPosition < threshold2
      );
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col ">
      <div>
        {AdvancedTrackingSystem.map((item, i) => (
          <RightsideSection
            id={item.id}
            key={i}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
            responsiveImage={item.responsiveImage}
          />
        ))}
        {RealTimeUpdates.map((item) => (
          <LeftsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
            responsiveImage={item.responsiveImage}
          />
        ))}
        {YourNeeds.map((item) => (
          <RightsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
            responsiveImage={item.responsiveImage}
          />
        ))}
        {Communication.map((item) => (
          <LeftsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
            responsiveImage={item.responsiveImage}
          />
        ))}
        <div className="hidden xl:block">
          <div className="flex justify-center items-center ">
            {showMobilePhone && (
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full flex justify-center items-center z-[-10]">
                <MobilePhone />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CardSectionResponsive = () => {
  // const Image1 = "assets/images/phone1.png";
  // const Image2 = "assets/images/phone2.png";
  // const Image3 = "assets/images/phone3.png";
  // const Image4 = "assets/images/phone4.png";

  return (
    <div className="flex flex-col w-full">
      <div className="w-full">
        {AdvancedTrackingSystem.map((item) => (
          <RightsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
            responsiveImage={item.responsiveImage}
          />
        ))}
        {RealTimeUpdates.map((item) => (
          <LeftsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
            responsiveImage={item.responsiveImage}
          />
        ))}
        {YourNeeds.map((item) => (
          <RightsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
            responsiveImage={item.responsiveImage}
          />
        ))}
        {Communication.map((item) => (
          <LeftsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
            responsiveImage={item.responsiveImage}
          />
        ))}
      </div>
    </div>
  );
};

const CardSectionMedium = () => {
  return (
    <div className="flex flex-col">
      <div className="">
        {AdvancedTrackingSystem.map((item) => (
          <RightsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
          />
        ))}
        {/* <div className="flex justify-center items-center">
            <div className="flex justify-center items-center">
              <img src={PhoneImages[1]} alt="phone" className="h-[600px]" />
            </div>
          </div> */}
      </div>
      <div className="-mt-10 lg:-mt-0">
        {RealTimeUpdates.map((item) => (
          <LeftsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
          />
        ))}
        {/* <div className="flex justify-center items-center">
            <div className="flex justify-center items-center">
              <img src={PhoneImages[2]} alt="phone" className="h-[600px]" />
            </div>
          </div> */}
      </div>
      <div className="-mt-10 lg:-mt-0">
        {YourNeeds.map((item) => (
          <RightsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
          />
        ))}
        {/* <div className="flex justify-center items-center">
            <div className=" flex justify-center items-center">
              <img src={PhoneImages[3]} alt="phone" className="h-[600px]" />
            </div>
          </div> */}
      </div>
      <div className="-mt-10 lg:-mt-0">
        {Communication.map((item) => (
          <LeftsideSection
            key={item.id}
            image={item.image}
            title={item.title}
            subtitle={item.subtitle}
            para={item.para}
            buttonText={item.buttonText}
          />
        ))}
        {/* <div className="flex justify-center items-center">
            <div className=" flex justify-center items-center">
              <img src={PhoneImages[4]} alt="phone" className="h-[600px]" />
            </div>
          </div> */}
      </div>
    </div>
  );
};

export { CardSection, CardSectionResponsive, CardSectionMedium };
