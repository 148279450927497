export const navlinks = [
  {
    title: "Home",
    path: "/",
    value: 0,
  },
  {
    title: "About",
    path: "/about",
    value: 1,
  },
  {
    title: "Get started",
    path: "/get-started",
    value: 2,
  },

  {
    title: "FAQ",
    path: "/faq",
    value: 3,
  },
  {
    title: "Contact",
    path: "/contact",
    value: 4,
  },
];
