import React, { useState } from "react";
import { TeamMembersResponsive } from "../../constants/Data";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";


const CarouselMobileView = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + 1) % TeamMembersResponsive.length
    );
  };

  const handlePrevious = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + TeamMembersResponsive.length) %
        TeamMembersResponsive.length
    );
  };

  return (
    <div className="">
      {TeamMembersResponsive.map((testimonial, i) => {
        if (i === currentIndex) {
          return (
            <div
              key={testimonial.id}
              className="flex flex-col items-center gap-2 p-4"
            >
              <div className="w-52 h-52">
                <img
                  src={testimonial.image}
                  alt={testimonial.id}
                  className="w-full h-full object-cover"
                />
              </div>
              <h1 className="text-cBlack text-lg font-bold w-full text-center">
                {testimonial.name}
              </h1>
              <p className="text-[#777777] text-xs font-semibold text-center w-full uppercase">
                {testimonial.title}
              </p>
            </div>
          );
        }
        return null;
      })}
      <div className="flex justify-between w-full px-4 mt-4">
        <div onClick={handlePrevious} className="">
          <FaArrowLeft />
        </div>
        <div onClick={handleNext} className="">
          <FaArrowRight />
        </div>
      </div>
    </div>
  );
};

const OurTeamCarousel = () => {
  const [visibleCards, setVisibleCards] = useState(2);
  const handleViewMore = () => {
    setVisibleCards(visibleCards + 2);
  };
  const handleViewLess = () => {
    setVisibleCards(visibleCards - 2);
  };
  return (
    <div>
      <div className="grid grid-cols-2 md:grid-cols-4 ">
        {TeamMembersResponsive.slice(0, visibleCards).map((testimonial, i) => {
          return (
            <div
              key={testimonial.id}
              className="flex flex-col items-center gap-2 h-full p-4"
            >
              <img src={testimonial.image} alt={testimonial.name} />
              <h1 className="text-cBlack text-lg font-700  w-full text-center">
                {testimonial.name}
              </h1>
              <p className="text-[#777777] text-xs font-semibold text-center w-full uppercase">
                {testimonial.title}
              </p>
            </div>
          );
        })}
      </div>
      {TeamMembersResponsive.length > visibleCards && (
        <div className="flex justify-center mt-4 w-full items-center mx-auto">
          <div className="flex justify-center mx-auto w-full">
            <FaChevronDown onClick={handleViewMore} className="text-cardBg2" />
          </div>
          {visibleCards > 2 && (
            <div className="flex justify-center mx-auto w-full">
              <FaChevronUp onClick={handleViewLess} className="text-cardBg2" />
            </div>
          )}
        </div>
      )}
    </div>

    // </Slider>
  );
};

export { CarouselMobileView, OurTeamCarousel };
