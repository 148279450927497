import React from 'react'
import { FAQSection } from '../components/sections/Sections'
import FormBody from '../components/Form/FormBody'

const Help = () => {
  return (
    <div>
      <section className="text-gray-600 h-full xl:h-screen lg:h-full w-full  bgContainer">
        <div className="flex flex-col lg:flex-row w-full h-full pt-20 ">
          <div className="w-full h-full lg:hidden block">
            <div className="w-full h-full flex justify-end items-end">
              <img src="/assets/images/ContactUsNew.png" alt="" className="" />
            </div>
          </div>
          <div className="w-full h-full md:h-[60%] lg:h-full xl:h-full py-8 lg:py-0">
            <div className="w-full flex items-center ">
              <img
                src="/assets/icons/skoolbus-hero-bg-line.svg"
                alt=""
                className="hero-bg-line hidden xl:block "
              />
            </div>
            <div className="w-full flex flex-col xl:justify-start h-full  lg:pt-10 lg:pl-24 p-0 lg:gap-10 xl:gap-14">
              <h1 className="p-6 lg:p-0 leading-tight text-center lg:text-left text-3xl md:text-5xl lg:text-5xl xl:text-6xl font-700 text-[#444444]">
                Need Help?
              </h1>
              <p className=" text-center lg:text-left w-full px-4 lg:px-0 text-base lg:text-2xl xl:text-2xl md:px-24 xl:w-full md:text-2xl ">
                Have a question or need assistance?
                <br /> Fill out the form below, and our dedicated support team
                will promptly address your concerns.
              </p>
              <p className=" text-center lg:text-left w-full px-4 lg:px-0 text-base lg:text-2xl xl:text-2xl md:px-24 xl:w-full md:text-2xl ">
                To we are here for you. Tell us how we can help. Our support
                team is on hand to answer your questions.
              </p>
            </div>
          </div>
          <div className="w-full h-full hidden lg:block">
            <div className="w-full h-full justify-end items-end xl:hidden block">
              <img src="/assets/images/ContactUsNew.png" alt="" className="" />
            </div>
            <div className="w-full h-full flex justify-end items-end ">
              <div className="w-full  h-full contact-img xl:block hidden" />
            </div>
          </div>
        </div>
      </section>
      <FormBody />
      {/* <FAQSection /> */}
    </div>
  );
}

export default Help