import React from "react";
import { FAQSection } from "../components/sections/Sections";

const FAQ = () => {
  return (
    <div className="w-full">
      <section className="text-gray-600 h-full xl:h-screen lg:h-full w-full  bgContainer">
        <div className="flex flex-col lg:flex-row w-full h-full pt-20 ">
          <div className="w-full h-full lg:hidden block">
            <div className="w-full h-full flex justify-end items-end">
              <img src="/assets/images/FAQ.png" alt="" className="" />
            </div>
          </div>
          <div className="w-full h-full md:h-[60%] lg:h-full xl:h-full py-8 lg:py-0">
            <div className="w-full flex items-center ">
              <img
                src="/assets/icons/skoolbus-hero-bg-line.svg"
                alt=""
                className="hero-bg-line hidden xl:block "
              />
            </div>
            <div className="w-full flex flex-col xl:justify-start h-full  lg:pt-10 lg:pl-24 p-0 lg:gap-10 xl:gap-14">
              <h1 className="p-6 lg:p-0 leading-tight text-center lg:text-left text-3xl md:text-5xl lg:text-5xl xl:text-6xl font-700 text-[#444444]">
                FAQs
              </h1>
              <p className=" text-center lg:text-left w-full px-4 lg:px-0 text-base lg:text-xl xl:text-2xl md:px-24 xl:w-full md:text-2xl">
                Embark on a journey to discover comprehensive answers to a
                myriad of inquiries about SkoolBus in our specialized FAQ
                section. Your quest for information starts here, ensuring a
                smooth ride for both students and parents alike.
              </p>
            </div>
          </div>
          <div className="w-full h-full hidden lg:block">
            <div className="w-full h-full justify-end items-end xl:hidden block">
              <img src="/assets/images/FAQ.png" alt="" className="" />
            </div>
            <div className="w-full h-full flex justify-end items-end ">
              <div className="w-full  h-full faq-img xl:block hidden" />
            </div>
          </div>
        </div>
      </section>
      <FAQSection />
    </div>
  );
};

export default FAQ;
