import React, { useState, useEffect } from "react";
import ChatbotSplashScreen from "./Pages/ChatbotSplashScreen";
import "aos/dist/aos.css";
import ChatbotLanding from "./Pages/ChatbotLanding";


const Chatbot = () => {
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    // Use setTimeout to hide the splash screen after 5 seconds
    const splashScreenTimeout = setTimeout(() => {
      setShowSplashScreen(false);
    }, 1500);

    // Clear the timeout if the component unmounts
    return () => clearTimeout(splashScreenTimeout);
  }, []);

  return (
    <div>
      <div
        className={`fixed bottom-5 right-5 sm:right-8  border rounded-[40px] w-[320px] sm:w-[360px] h-[450px] sm:h-[420px] -z-10 ${
          showSplashScreen ? "" : "bg-white"
        }`}
      >
        {/* {showSplashScreen ? <ChatbotSplashScreen /> : <ChatbotQAScreen />} */}
        {showSplashScreen ? <ChatbotSplashScreen /> : <ChatbotLanding />}
      </div>
    </div>
  );
};

export default Chatbot;
