import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";
import RootLayout from "./layout/RootLayout";
import Landing from "./pages/Landing";
import About from "./pages/About";
import Help from "./pages/Help";
import FAQ from "./pages/FAQ";
import HowitWorks from "./pages/HowitWorks";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsNConditions from "./pages/Terms&Conditions";
import FeeandRefundPolicy from "./pages/FeeandRefundPolicy";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route index element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Help />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/get-started" element={<HowitWorks />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsNConditions />} />
        <Route path="/Fee-and-Refund-Policy" element={<FeeandRefundPolicy />} />
      </Route>
    )
  );

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
