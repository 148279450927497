// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden !important;

  .texts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: full;
    width: auto;
    font-size: 20px;
    overflow: hidden;
    font-weight: 800;
    opacity: 0;
    color: #45115f;
    font-family: "Neue Haas Grotesk Text Pro Medium";

    @include media("<=phone") {
      font-size: 16px;
      width: 240px;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/preloader/PreLoader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kCAAkC;EAClC,WAAW;EACX,iBAAiB;EACjB,eAAe;EACf,SAAS;EACT,OAAO;EACP,QAAQ;EACR,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,2BAA2B;;EAE3B;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,UAAU;IACV,cAAc;IACd,gDAAgD;;IAEhD;MACE,eAAe;MACf,YAAY;IACd;EACF;AACF","sourcesContent":[".preloader {\n  height: 100vh;\n  height: calc(var(--vh, 1vh) * 100);\n  width: 100%;\n  background: white;\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  z-index: 1000;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: white;\n  overflow: hidden !important;\n\n  .texts-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height: full;\n    width: auto;\n    font-size: 20px;\n    overflow: hidden;\n    font-weight: 800;\n    opacity: 0;\n    color: #45115f;\n    font-family: \"Neue Haas Grotesk Text Pro Medium\";\n\n    @include media(\"<=phone\") {\n      font-size: 16px;\n      width: 240px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
