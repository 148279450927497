import React from "react";
import {
  PrivacyPolicyPoints,
  TermsNConditionsGeneral,
} from "../constants/Privacy-Policy";
import { GoDotFill } from "react-icons/go";
import { PiDotOutlineDuotone } from "react-icons/pi";
import { Link, useNavigate } from "react-router-dom";

const TermsNConditions = () => {
  const navigate = useNavigate();

  const handleRoute = () => {
    navigate("/privacy-policy");
  };

  const handleSubscriptionFee = () => {
    navigate("/Fee-and-Refund-Policy");
  };

  return (
    <div className="h-full w-full p-6 sm:p-24 bg-white flex flex-col gap-0 lg:gap-10">
      <div className="sm:h-[30vh] h-[40vh] w-full bg-white flex">
        <span className="flex justify-start my-auto text-3xl sm:text-5xl mx-suto sm:mx-0 font-bold lg:w-2/3 text-left">
          SkoolBus Terms <br className="block lg:hidden" /> and Conditions
        </span>
      </div>
      <div className="flex flex-col gap-5 ">
        <span className="text-lg font-bold">
          Please read these SkoolBus Terms and Conditions carefully
          (“Agreement”, “Terms and conditions”, “Terms”). This Agreement is
          legally binding and made by and between SB Technologies Pvt Ltd (the
          "Company", “our”, “us”, “we”) and each individual driver or parent
          (the “you”, “your”, “user(s)”) that uses the SkoolBus software, media
          channel, mobile website or SkoolBus mobile application (“Portal”)
          created and maintained by the Company (“Service”). By accessing or
          using the Portal, you agree to be bound by the Terms and Services
          described in this Agreement. If you do not agree to these Terms and
          Conditions, do not subscribe to, access, or use the Portal or Service.
          Each time any Portal is used, an implicit affirmation is given for the
          acceptance of the terms and conditions herein.
        </span>
        <span className="text-2xl font-bold">Purpose</span>
        <span className="text-lg font-bold text-[#666666]">
          The Company provides a software which is hosted by Google Cloud
          Platform Web Service to help you to track the transportation method
          your child takes to school enhancing child safety and the ability for
          you to track the child’s real time location when the child is in the
          school transport. This application helps the registered driver to pick
          up and drop off children.
        </span>
        <span className="text-2xl font-bold">Acceptance of Terms </span>
        <span className="text-lg font-bold text-[#666666]">
          The Service is offered to you conditional upon your acceptance of the
          terms, conditions, and notices contained herein and updated from time
          to time. We reserve the right to modify these Terms, or any part
          thereof, or add or remove terms at any time, and such modifications,
          additions, or deletions will be effective immediately upon posting.
           Although we will endeavor to provide you with notice of material
          changes, your use of the Portal after such posting shall be deemed to
          constitute acceptance by you of such modifications, additions or
          deletions. The Company may modify, suspend, discontinue, or restrict
          the use of any portion of the Portal, including the availability of
          any portion of the content at any time, without notice or liability.
        </span>
        <span className="text-2xl font-bold">
          Description of Service and Service Availability
        </span>
        <span className="text-lg font-bold text-[#666666]">
          This Portal will help parents to track their children’s live location
          while the children are on their school journey. The drivers will be
          able to track the children’s home locations and school locations. Pick
          them up from the home locations and drop them at their schools. The
          driver will be able to send alerts and push notifications to the
          parents when the school bus is nearby or reached their pickup or drop
          off locations. The drivers will be able to inform parents of emergency
          situations e.g., Vehicle breakdown, traffic, child’s sickness
          situations, etc. via the Portal through push notifications. The parent
          will be able to acknowledge the children’s pickup or drop offs via the
          Portal. The parent will be able to mark the child’s availability for
          the school journey (marking absent or not) and inform the child’s
          sickness situations via the Portal as push notifications.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          The Portal provides authorized users with access to information
          displayed on the Portal.  The Company will use its best efforts to
          make the Portal available 24 hours a day, every day. However, the
          Company makes no representation or warranty that uninterrupted service
          will be available, especially since third-party services such as
          Google Maps and Firebase Google service are used to obtain real-time
          information.  User agrees and acknowledges that the Portal will, at
          times, be unavailable due to maintenance, service upgrades, or other
          mechanical or electronic failures. The Company shall not be
          responsible for any error, omission, interruption, deletion, defect,
          delay in operation or transmission, communications line failure, theft
          or destruction or unauthorized access to the Portal.  The Company is
          not responsible for any problems or technical malfunctions of any
          telephone or fiber network or lines, computer online systems, servers
          or providers, computer equipment, software, failure of any email to be
          received by the Company on account of technical problems or traffic
          congestion on the internet or at any website, or any combination
          thereof, including any injury or damage to your computer or
          peripherals related to use or downloading any materials from the
          Portal.
        </span>
        <span className="text-2xl font-bold">Price and payment</span>
        <span className="text-lg font-bold text-[#666666]">
          The payment and the payment terms shall be according to our
          Subscription Fee policy
          <span
            onClick={() => {
              handleSubscriptionFee();
            }}
            className="text-cardBg2 cursor-pointer ml-2"
          >
            Click here
          </span>
        </span>
        <span className="text-2xl font-bold">Limited Right to Use </span>
        <span className="text-lg font-bold text-[#666666]">
          You are granted only a limited, non-exclusive, non-transferable,
          non-sublicensable, revocable license to the Portal and the Service for
          use solely by you for personal use and not for republication,
          distribution, assignment, sublicense, sale, preparation of derivative
          works or other use. The Service should only be used within the
          designated geographical area and for authorized transportation
          purposes. Any misuse or deviation from the intended use may result in
          penalties or legal action. You expressly acknowledge that you do not
          acquire any ownership rights by downloading any copyrighted material
          from or through the SkoolBus Portal.
        </span>
        <span className="text-2xl font-bold">
          Provision of Information and User Conduct{" "}
        </span>
        <span className="text-lg font-bold text-[#666666]">
          You agree that you are responsible for your own conduct and all
          activities under your user profile and for all Content that is
          published by anyone using your user profile in the Portal and for any
          consequences arising as a result thereof. You agree to use the Portal
          only for the purpose of tracking the child, purposes that are legal,
          proper and in accordance with Terms contained herein, and all
          applicable laws and regulations in the relevant legal jurisdictions.
          Without limiting the foregoing, you represent and warrant to the
          Company that you have the rights and authority to submit your Content
          to the Company in connection with your use of the Portal, and such
          submission and publication of such Content through the Portal does not
          and will not infringe the rights of any person or third party.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          We have the right to create different classes of users of the Portal
          and have restricted areas of access to each user, granting access to
          certain areas of the Portal only to certain users.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          You further Agree to:
        </span>
        <span className="flex gap-2 font-bold text-[#555555]">
          <div>
            <PiDotOutlineDuotone />
          </div>
          <span className="-mt-1">
            {" "}
            represent yourself accurately and truthfully at all times;
          </span>
        </span>
        <span className="flex gap-2 font-bold text-[#555555]">
          <div>
            <PiDotOutlineDuotone />
          </div>
          <span className="-mt-1">
            grant us the right to verify independently by any lawful means any
            information that you post on the Portal about yourself;
          </span>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          You further represent, warrant and agree to NOT:
        </span>
        {PrivacyPolicyPoints.map((item, key) => {
          return (
            <div className="flex flex-col sm:px-12 pt-4 font-bold text-[#555555]">
              <span className="flex gap-2">
                <div>
                  <PiDotOutlineDuotone />
                </div>
                <span className="-mt-1">{item.ponit}</span>
              </span>
            </div>
          );
        })}
        <span className="flex gap-2 px-8 font-bold text-[#666666]">
          <div>
            <PiDotOutlineDuotone />
          </div>
          <span className="-mt-1">
            contains nudity, sexually graphic material, obscene, vulgar,
            profane, indecent or endorse or advocate illegal or immoral
            activity, or violate the rights of any third party or any material
            that is otherwise deemed explicit by the Company
          </span>
        </span>
        <span className="flex gap-2 px-8 font-bold text-[#666666]">
          <div>
            <PiDotOutlineDuotone />
          </div>
          <span className="-mt-1 font-bold text-[#666666]">
            contains threatening, abusive, harassing, defamatory, libelous,
            invasive, hateful, or racially, ethnically or otherwise
            objectionable.
          </span>
        </span>
        <span className="text-base font-bold text-[#666666]">
          If you encounter any of these prohibited uses, we encourage you to
          report them to us immediately to
          <Link to="/contact" className="font-bold text-cardBg2 pl-0.5">
            privacy@skoolbus.app
          </Link>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          In the event of any breach of this clause by the user, the Company
          shall be entitled to seek injunctive relief, as well as any other
          legal or equitable remedies available.
        </span>
        <span className="text-2xl font-bold">User Password and Security</span>
        <span className="text-lg font-bold text-[#666666]">
          When registering through the Portal for the Service, a user may be
          asked to create a user profile and provide us with specific
          information including, but not limited to, name, telephone number and
          a valid email address. By doing so, each user represents that the user
          is the individual who is registering, and that the user has the right
          to provide information through the Portal. You further acknowledge
          that we have no responsibility for verifying the identity of users and
          determining whether a particular user is authorized to use the Portal.
          Using the Portal and its related Service requires the use of a
          one-time password (OTP) connected to your mobile number. You
          acknowledge that OTP is an adequate form of security and that any
          additional security measures such as dual authentication, while
          enhancing security, are not necessary. You are solely responsible for
          (1) authorizing, monitoring, controlling access to and maintaining
          strict confidentiality of the OTP, (2) not allowing another person to
          use your OTP, (3) promptly informing the Company in writing of any
          need to deactivate a user profile due to security concerns or
          otherwise.  The Company is not liable for any harm related to the
          misuse or theft of OTP, disclosure of OTP, or your authorization to
          allow a third-party access to use the Service using your OTP.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          You acknowledge that OTP is an adequate form of security and that any
          additional security measures such as dual authentication, while
          enhancing security, are not necessary. You are solely responsible for
          (1) authorizing, monitoring, controlling access to and maintaining
          strict confidentiality of the OTP, (2) not allowing another person to
          use your OTP, (3) promptly informing the Company in writing of any
          need to deactivate a user profile due to security concerns or
          otherwise.  The Company is not liable for any harm related to the
          misuse or theft of OTP, disclosure of OTP, or your authorization to
          allow a third party access to use the Service using your OTP.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          The Company will use its best efforts to make the Portal secure from
          unauthorized access.  The third party provider of software and hosting
          for the Portal provides encryption on communication between the Portal
          and your device and also provides other security measures, some not
          disclosed to us to keep them more secure.  However, you agree that you
          recognize that a risk still exists, however small, that a breach of
          such security may occur. You agree that you shall not attempt to
          disable or circumvent any security features on the Portal or ask any
          third party to do so on your behalf.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          THE COMPANY MAKES NO WARRANTY, EXPRESS OR IMPLIED, REGARDING THE
          EFFICACY OF THE SECURITY OF THE PORTAL OR THE SERVICE AND SHALL NEVER
          BE LIABLE FOR ANY ACTUAL OR CONSEQUENTIAL DAMAGES, CLAIMED OR
          OTHERWISE, ARISING FROM ANY BREACH OR ALLEGED BREACH OF SECURITY OF
          THE PORTAL.
        </span>
        <span className="text-2xl font-bold">
          Termination of User Profile and Logon Ability{" "}
        </span>
        <span className="text-lg font-bold text-[#666666]">
          You agree to notify the Company when your user profile is to be
          terminated or when you decide to unsubscribe.  You cannot be assured
          that access has been terminated until you receive confirmation of the
          termination from the Company. In addition, weekends, holidays and
          other non-business days could cause a delay in the receipt of such
          request for termination. Upon cancellation of the Service, the Service
          will be discontinued at the end of the existing billing period.
        </span>
        <span className="text-2xl font-bold">Liability Disclaimer</span>
        <span className="text-lg font-bold text-[#666666]">
          To the maximum extent permitted by applicable law, all information and
          real time data is provided is "as is" without warranty or condition of
          any kind.  The Company hereby disclaims all warranties and conditions
          with regard to such information and communications, including without
          limitation, all implied warranties or conditions of merchantability,
          fitness for a particular purpose, title, and non-infringement.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          To the maximum extent permitted by applicable law, in no event shall
          the Company be liable for any direct, indirect, punitive, incidental,
          special, consequential damages, or any other damages whatsoever,
          including without limitation, damages for loss of use, data, or
          profits, arising out of or in any way connected with your use or the
          performance of the Portal or the Service, with the delay or inability
          to use the Portal or related Service, whether based in contract, tort,
          negligence, strict liability, or otherwise, even if the Company has
          been advised of the possibility of damages. Company’s total liability
          for direct damages suffered by the user or any of its affiliates under
          this Agreement or related to the Portal and the Service shall in no
          event exceed an amount equal to three (3) times the average monthly
          fee paid by you to the Company under this Agreement.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          We do not confirm the identity of each user. We do not control the
          conduct of users on the Portal except as noted herein. In situations
          where disputes arise between users, you hold us harmless from any and
          all associated claims and damages (actual or consequential and direct
          or indirect) of every kind and nature, known and unknown, suspected
          and unsuspected, disclosed and undisclosed, arising out of or in any
          way connected with such disputes. Other than our security and data
          protection obligations expressly set forth in our privacy policy, we
          assume no responsibility or liability for your data, and you shall be
          solely responsible for your data and the consequences of using,
          disclosing, storing, or transmitting it. It is hereby clarified that
          the Company shall not monitor and/or moderate your data and there
          shall be no claim against the Company for whatsoever reason other than
          in terms of the Company privacy policy.
        </span>
        <span className="text-2xl font-bold">
          SkoolBus Portal Operation Disclaimer
        </span>
        <span className="text-lg font-bold text-[#666666]">
          We do not guarantee that the Portal shall be error free or that our
          servers are free of viruses or other potentially destructive entities.
          You understand that the Company cannot and do not guarantee or warrant
          that information or files downloaded from the Portal will be free of
          viruses or other destructive code. You are responsible for
          implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output. We are not liable for any costs associated with the
          contraction of viruses, damage to equipment (either hardware or
          software), or loss of data arising from the utilization of the Portal
          or the Services. We are not liable for any costs for the replacement
          of damaged equipment (either hardware or software) or for any loss of
          data as the result of a virus or other destructive entity contracted
          through the use of the Portal. You use the Portal at your own risk and
          agree to hold us harmless from any and all liability arising from such
          use.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          We do not warrant the operation or availability of the Portal.
          Hardware or software issues may at times cause the Portal to slow down
          or fail to function properly. You expressly agree that the use of, or
          inability to use, the Service is at your sole risk. We are not liable
          for any consequences of the Portal or the Service not being fully
          operational or available and shall be held harmless from any claims or
          disputes arising from such inoperability or unavailability. You
          acknowledge that any damages resulting from lack of operability or
          availability are born solely by you.
        </span>
        <span className="text-2xl font-bold">External Database Disclaimer</span>
        <span className="text-lg font-bold text-[#666666]">
          We use external databases for specific functionalities related to the
          Service we provide. We do not control the content or security of these
          external databases. Errors and/or omissions may periodically occur and
          are foreseeable when relying upon external databases and we are unable
          to assume responsibility for oversight of these errors and/or
          omissions. We make commercially reasonable efforts to ensure that
          information gleaned from external databases are accurate, but assume
          no responsibility for the accuracy of such information. Although we
          make commercially reasonable efforts to ensure the information
          received from an external database is free from viruses, malware, or
          any destructive instrumentality, we assume no responsibility for the
          presence of any viruses, malware, or any other destructive
          instrumentality transmitted to us from an external database.
        </span>
        <span className="text-2xl font-bold">Indemnification </span>
        <span className="text-lg font-bold text-[#666666]">
          To the extent allowed by applicable law, user agrees to defend,
          indemnify and hold harmless Company and any of its directors,
          officers, members, shareholders, and employees and their heirs from
          and against any and all third party claims and all liabilities,
          assessments, losses, costs or damages resulting from or arising out of
          or related to user’s use of the Portal and the Service, or any breach
          by user of any term or condition of this Agreement.  If any such
          action shall be brought against any indemnified party, they shall
          notify user in writing and user shall assume and control the defense
          and settlement of each such action, including the employment of
          counsel and payment of all expenses associated with such action.  Any
          indemnified party shall have the right to employ separate counsel in
          any such action and participate in the defense, at their own cost
          unless otherwise agreed to by user.
        </span>
        <span className="text-2xl font-bold">
          Links to Third Party Websites{" "}
        </span>
        <span className="text-lg font-bold text-[#666666]">
          The Portal may contain links and pointers to websites operated by
          third parties. These links are provided solely as a convenience to you
          and not as an endorsement by us of the contents of such third-party
          websites. We are not responsible for the content of linked third-party
          websites and do not make any representations regarding the content or
          accuracy of materials on such third-party websites. If you decide to
          access linked third-party websites, you do so at your own risk.
        </span>
        <span className="text-2xl font-bold">Term</span>
        <span className="text-lg font-bold text-[#666666]">
          The term of this Agreement extends past the life of the Service and
          remains in full force and effect even if access is terminated to you
          to the Portal and the Service or if you cease to be a user.
        </span>
        <span className="text-2xl font-bold">Termination</span>
        <span className="text-lg font-bold text-[#666666]">
          Your rights under these Terms will automatically terminate without
          notice if you fail to comply with any provision of these Terms.
          Further, the Company may terminate the Service for any action taken by
          you that the Company believes in its sole discretion is an
          inappropriate use of the Service even if not specifically detailed by
          these Terms, including, any use of the Service that is prohibited by
          the law. The parties expressly acknowledge and agree that all
          provisions of these Terms that concern Copyright or other protectable
          interests of the Company shall remain in full force and effect
          notwithstanding termination of any warranty or use of the Service.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          Termination of this Agreement shall not limit either party from
          pursuing other remedies available to it, including injunctive relief.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          Upon any termination of this Agreement, the Company shall have the
          right to maintain a copy of all user content in accordance with, and
          for the period of time it determines is required or permitted by,
          Applicable Law.
        </span>
        <span className="text-2xl font-bold">Privacy Policy </span>
        <span className="text-lg font-bold text-[#666666]">
          Our Privacy Policy, as it may change from time to time, is a part of
          this Agreement.{" "}
          <Link to={"/privacy-policy"} className="text-cardBg2">
            Click here
          </Link>
        </span>
        <span className="text-2xl font-bold">Confidential Information </span>
        <span className="text-lg font-bold text-[#666666]">
          All Confidential Information disclosed hereunder will remain the
          exclusive and confidential property of the disclosing party. The
          receiving party will not disclose the Confidential Information of the
          disclosing party and will use at least the same degree of care,
          discretion and diligence in protecting the Confidential Information of
          the disclosing party as it uses with respect to its own confidential
          information, but in no case less than reasonable care. The receiving
          party will limit access to Confidential Information to its affiliates,
          employees and authorized representatives with a need to know and will
          instruct them to keep such information confidential. Notwithstanding
          the foregoing, the receiving party may disclose Confidential
          Information of the disclosing party (i) to the extent necessary to
          comply with any applicable law, (ii) as appropriate, to respond to any
          summons or subpoena or in connection with any litigation.
        </span>
        <span className="text-2xl font-bold">Intellectual property </span>
        <span className="text-lg font-bold text-[#666666]">
          You acknowledge that all rights, title and interest in and to the
          Portal and the Service, including but not limited to its logos,
          designs, images, symbols remains with the Company, except as expressly
          set forth in this Agreement, and that the unauthorized redistribution
          or dissemination Portal or the Service could materially and
          irreparably harm the Company and in the event any breach of this
          clause, the Company is entitled to the right to seek injunctive
          relief, as well as any other legal or equitable remedies available.
        </span>
        <span className="text-lg font-bold text-[#666666]">
          You agree that all copyright, trademarks, trade secrets, patents and
          other intellectual property of the Company belongs to the Company and
          you undertake that you shall not challenge the title and the ownership
          of the SkoolBus application and its Service and/or any other designs,
          inventions, applications, (including but not limited to computer
          programs, source codes, object codes, system software, system
          specifications, manual, documentation) developed and maintained by the
          Company under any circumstances.
        </span>
        <span className="text-2xl font-bold">General </span>
        {TermsNConditionsGeneral.map((item) => {
          return (
            <div className="flex w-full gap-2">
              <GoDotFill className="text-[#666666] " />
              <div className="text-lg font-bold w-full -mt-1">
                <span className="text-[#444444]"> {item.MainPoint}</span> -{" "}
                <span className="text-[#555555]">{item.point}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TermsNConditions;
