import React, { useEffect } from "react";
import { preLoaderAnim } from "../animations";
import "./PreLoader.css";
import { motion } from "framer-motion";
import { Oval } from "react-loader-spinner";

const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);
  return (
    <div className="preloader z-[1000]">
      <div className="flex flex-col gap-4 items-center justify-center">
        <div className="flex flex-row gap-1 items-center justify-center">
          <motion.img src="Icon_logo.png" width="48" height="48" alt="logo" />
        </div>
        <div className="flex flex-row gap-2 items-center">
          <Oval
            height={20}
            width={20}
            color="#45115f"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#955cb2"
            strokeWidth={7}
            strokeWidthSecondary={2}
          />
          <div className="text-[#45115f] text-lg">Loading ....</div>
        </div>
      </div>
    </div>
  );
};

export default PreLoader;
