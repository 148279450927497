import React from "react";
import { useState, useEffect } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";

const ButtonPrimary = ({ text, fun }) => {
  return (
    <div>
      <button
        onClick={fun}
        className="flex px-10 py-3 bg-secondary rounded-md text-cBlack text-base font-600 hover:shadow-md hover:bg-secondary2 hover-text-black transition duration-300 ease-in-out"
      >
        {text}
      </button>
    </div>
  );
};

const ButtonPurple = ({ text, fun }) => {
  return (
    <div>
      <button
        onClick={fun}
        className="flex px-5 sm:px-10 py-1 sm:py-3 bg-main rounded-md text-white text-base font-600 hover:shadow-md hover:bg-[#331940] hover-text-black transition duration-300 ease-in-out"
      >
        {text}
      </button>
    </div>
  );
};

const ButtonSecondary = ({ text, fun }) => {
  return (
    <div>
      <button
        onClick={fun}
        className="flex px-10 py-3 bg-white border border-main rounded-md text-main text-base font-semibold hover:bg-main hover:bg-opacity-80 hover:text-white transition duration-300 ease-in-out"
      >
        {text}
      </button>
    </div>
  );
};

const ScrollUp = ({ onClick }) => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Add a scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // When the user scrolls down a certain distance, show the button
    if (window.scrollY > 10) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    // Scroll to the top of the page when the button is clicked
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Add smooth scrolling behavior
    });
  };
  return (
    <div>
      {showButton && (
        <div
          className=" bottom-5 right-2 sm:right-5 bg-main text-white border-none rounded-[40px] w-12 h-12 flex items-center justify-center cursor-pointer  transition-all duration-300"
          onClick={scrollToTop}
        >
          <AiOutlineArrowUp />
        </div>
      )}
    </div>
  );
};

const DownloadButton = ({ text, logo, title, fun }) => {
  return (
    <div
      onClick={fun}
      className="cursor-pointer flex items-center justify-center gap-2 h-[60px]  w-[175px] bg-black rounded-xl text-white text-base font-semibold hover:opacity-80 transition duration-300 ease-in-out hover:scale-75"
    >
      <div className="">{logo}</div>
      <div className="flex flex-col justify-start items-start gap-0">
        <div className="text-[12px] opacity-90">{title}</div>
        <div className="text-[20px]">{text}</div>
      </div>
    </div>
  );
};

export { ButtonPrimary, ButtonSecondary, DownloadButton, ScrollUp, ButtonPurple };
