import axios from "axios";

export const SendEmail = async ({
  firstName,
  lastName,
  message,
  email,
  mobileNumber,
  setSend,
}) => {
  try {
    const datas = { firstName, lastName, message, mobileNumber, email };
    let res = await axios.post(process.env.REACT_APP_SERVER_URL + `/send`, datas);
    if (res) {
      setSend(res.data);
    }
  } catch (error) {
    alert(error.response.data.message);
  }
};

export const SubscribeEmail = async ({ email, setSend }) => {
  try{
    const datas = { email };
    let res = await axios.post(process.env.REACT_APP_SERVER_URL + `/subscribe`, datas);
    if (res) {
      setSend(res.data);
    }
  } catch (error) {
    alert(error.response.data.message);
  }
};
