import { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { motion, useMotionValue, useTransform } from "framer-motion";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import "tippy.js/dist/tippy.css";
import Tooltip from "../Tooltip/Tooltip";

const TestimonialCard = ({ data }) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [100, -100], [-30, 30]);

  return (
    <div style={{ perspective: 100000 }} className="">
      <Tooltip text={data.message} width={"w-[320px] mb-2"}>
        <motion.div className="flex flex-col justify-center gap-6 items-center ">
          <motion.img
            src={data.image}
            alt={data.name}
            className="object-cover rounded-full cursor-pointer"
            whileHover={{
              scale: 1.05,
              rotateY: 10,
              boxShadow: "0 0 0 3px purple", // Add border on hover
            }}
            transition={{ duration: 0.3 }}
            draggable={false}
            style={{ x, y, rotateX, rotateY, z: 10 }}
          />
          <span className="text-cBlack text-lg font-700 tracking-wide w-full text-center">
            {data.name}
          </span>
          <span className="text-[#777777] text-sm font-semibold NeueLight tracking-wider -m-6 text-center w-full uppercase">
            {data.title}
          </span>
        </motion.div>
      </Tooltip>
    </div>
  );
};

const AboutUsCard = ({ data }) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [100, -100], [-30, 30]);

  return (
    <div style={{ perspective: 100000 }} className="hover:scale-105">
      <motion.div className="flex flex-col justify-center gap-6 items-center ">
        <div className="w-[203px] h-[203px]">
          <motion.img
            src={data.image}
            alt={data.name}
            className="object-cover rounded-full cursor-pointer"
            whileHover={{
              scale: 1.05,
              rotateY: 10,
              boxShadow: "0 0 0 3px purple", // Add border on hover
            }}
            transition={{ duration: 0.3 }}
            draggable={false}
            style={{ x, y, rotateX, rotateY, z: 10 }}
          />
        </div>
        <span className="text-cBlack text-lg font-700 tracking-wide w-full text-center">
          {data.name}
        </span>
        <span className="text-[#777777] text-sm font-semibold NeueLight tracking-wider -m-6 text-center w-full uppercase">
          {data.title}
        </span>
      </motion.div>
    </div>
  );
};

const TestimonialCardResponsive = ({ image, name, title }) => {
  return (
    <div className="flex flex-col gap-4">
      <img src={image} alt="testimonial" className="object-cover" />
      <span className="text-textMain text-xs font-semibold NeueBold">
        {name}
      </span>
      <span className="text-textMain text-xs font-semibold Neue">{title}</span>
    </div>
  );
};

const TestimonialCardMobileview = ({ data }) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);
  const rotateX = useTransform(y, [-100, 100], [30, -30]);
  const rotateY = useTransform(x, [100, -100], [-30, 30]);

  return (
    <div
      style={{ perspective: 100000 }}
      className="flex flex-col justify-center items-center w-full h-full"
    >
      <motion.div className="flex flex-col justify-center gap-4 items-center w-full h-full">
        <motion.img
          src={data.image}
          alt={data.name}
          className=""
          whileHover={{
            scale: 1.05,
            rotateY: 10,
            boxShadow: "0 0 0 3px purple", // Add border on hover
          }}
          transition={{ duration: 0.3 }}
          draggable={false}
          style={{ x, y, rotateX, rotateY, z: 10 }}
        />
        <span className="text-cBlack text-lg font-700 tracking-wide w-full text-center">
          {data.name}
        </span>
        <span className="text-[#777777] text-sm font-semibold NeueLight tracking-wider -m-6 text-center w-full uppercase">
          {data.title}
        </span>
        <span className="text-[#777777] text-xs font-semibold NeueLight tracking-wider -m-6 text-center w-full uppercase">
          {data.message}
        </span>
      </motion.div>
    </div>
  );
};

const InfoCard = ({ data }) => {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <ScrollTrigger
      onEnter={() => setCounterOn(true)}
      onExit={() => setCounterOn(false)}
    >
      <div className="bg-cardBg rounded-xl p-5 flex flex-col gap-4 justify-center items-center sm:w-[200px]  h-[200px] z-20">
        <div>
          <img src={data.image} alt={data.id} className="" />
        </div>
        <div>
          <div className="NeueBold text-4xl font-medium text-center">
            {counterOn && (
              <CountUp
                start={0}
                end={parseInt(data.number)}
                duration={2}
                delay={0}
              />
            )}{" "}
            {data.subtitle}
          </div>
          <div className="Neue text-base font-light text-center">
            {data.name}
          </div>
        </div>
      </div>
    </ScrollTrigger>
  );
};

const HowItWorksCard = ({ data }) => {
  return (
    <div className="w-full bg-white h-[185px] shadow-[0px_4px_50px_#0000001a] rounded-2xl flex justify-between items-center px-9 gap-10 z-20">
      <div className="flex flex-col gap-2 w-full">
        <div className="text-xl lg:text-3xl  font-semibold">{data.title}</div>
        <div className="text-base lg:text-2xl font-normal">{data.para}</div>
      </div>
      <div className="">
        <img src={data.image} alt={data.id} className="" style={{width:'70px'}}/>
      </div>
    </div>
  );
};

const FAQsCard = ({ items }) => {
  const [minimize, setMinimize] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const toggleIcons = () => {
    setMinimize((prevState) => !prevState);
    setIsSelected(!isSelected);
  };

  const cardStyle = {
    background: isSelected ? "rgba(69, 17, 95, 0.05)" : "",
    transition: "all 0.3s ease",
    cursor: "pointer",
    "&:hover": {
      background: isSelected ? "rgba(69, 17, 95, 0.05)" : "",
      transition: "all 0.3s ease",
    },
  };

  return (
    <div style={cardStyle} className="w-full">
      <div className="flex flex-row justify-between items-center w-full sm:p-8 p-2 sm:gap-10 gap-8">
        <div onClick={toggleIcons} className="w-[25px] h-[25px]">
          {minimize ? (
            <AiOutlineMinus className="cursor-pointer h-[25px] w-[25px] text-main" />
          ) : (
            <AiOutlinePlus className="cursor-pointer h-[25px] w-[25px] text-main" />
          )}
        </div>
        <div className="text-lg font-bold w-full p-2 lg:p-0  text-textMain">
          {items.question}
        </div>
      </div>
      {minimize && (
        <div className="text-left text-base font-medium pb-5 pr-20 sm:pl-24 pl-20">
          {items.answer}
        </div>
      )}
    </div>
  );
};

const OurTeamCard = ({ id, name, title, image }) => {
  return (
    <div key={id} className="flex flex-col items-center gap-2 h-full p-4">
      <img src={image} alt={name} />
      <h1 className="text-cBlack text-lg font-700  w-full text-center">
        {name}
      </h1>
      <p className="text-[#777777] text-xs font-semibold text-center w-full uppercase">
        {title}
      </p>
    </div>
  );
};

export {
  TestimonialCard,
  InfoCard,
  HowItWorksCard,
  FAQsCard,
  AboutUsCard,
  TestimonialCardResponsive,
  TestimonialCardMobileview,
  OurTeamCard,
};
