import { useState, useEffect } from "react";
import { Provider } from "react-redux";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import Footer from "./FooterSection";
import PreLoader from "../components/preloader/PreLoader";
import ChatbotIcon from "../components/chatbot/ChatbotIcon";
import { ScrollUp } from "../components/buttons/Buttons";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import chatbotReducer from "../store/reducers/ChatbotReducers";

const rootReducer = combineReducers({
  chatbot: chatbotReducer,
});
const store = createStore(rootReducer, applyMiddleware(thunk));
const RootLayout = () => {
  const [showChatbotIcon, setShowChatbotIcon] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the scroll position from the top of the page
      const scrollPosition = window.scrollY || window.pageYOffset;

      // Adjust this value based on when you want the ChatbotIcon to appear
      const scrollThreshold = 200;

      if (scrollPosition > scrollThreshold) {
        setShowChatbotIcon(true);
      } else {
        setShowChatbotIcon(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Provider store={store}>
      <PreLoader />
      <Navbar />
      <main>
        <Outlet />
      </main>
      <div className="fixed bottom-1 right-5 z-[20] flex flex-col gap-2">
        <ChatbotIcon />
        {/* <ScrollUp /> */}
      </div>

      <Footer />
    </Provider>
  );
};

export default RootLayout;
