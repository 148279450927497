const ValidateEmail = ({ email, setEmailError }) => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && !email.match(emailRegex)
    ? setEmailError("Please enter a valid email")
    : setEmailError("");
};

const ValidateFirstName = ({ firstName, setFirstNameError }) => {
  const firstNameRegex = /^[a-zA-Z]+$/;
  const maxFirstNameLength = 10; // You can adjust this value according to your requirements

  if (!firstName) {
    setFirstNameError("");
  } else if (!firstName.match(firstNameRegex)) {
    setFirstNameError("Please enter a valid first name");
  } else if (firstName.length > maxFirstNameLength) {
    setFirstNameError(
      `First name must be at most ${maxFirstNameLength} characters`
    );
  } else {
    setFirstNameError("");
  }
};


const ValidateLastName = ({ lastName, setLastNameError }) => {
  const lastNameRegex = /^[a-zA-Z]+$/;
  return lastName && !lastName.match(lastNameRegex)
    ? setLastNameError("Please enter a valid last name")
    : setLastNameError("");
};

const ValidateMessage = ({ message, setMessageError }) => {
  return message && message.length < 10
    ? setMessageError("Message is too short")
    : message && message.length > 500
    ? setMessageError("Try to make it shorter and meaningful")
    : setMessageError("");
};

const ValidatePhone = ({ mobileNumber, setPhoneError }) => {
  var phoneRegular = /^(?:\+94|0094|0)?7\d{8}$/;
  return mobileNumber && !mobileNumber.match(phoneRegular)
    ? setPhoneError("Phone Number not valid")
    : setPhoneError("");
};

export {
  ValidateEmail,
  ValidateFirstName,
  ValidateLastName,
  ValidateMessage,
  ValidatePhone,
};
