import React from "react";
import {
  DownloadSection,
  HeaderSection,
  HeroTestimonialSection,
} from "../components/sections/Sections";
import {
  CardSection,
} from "../components/cards/CardSection";

const Landing = () => {
  return (
    <div>
      <HeaderSection />
      <HeroTestimonialSection />
      <div className="">
        <CardSection />
      </div>
      {/* <div className="sm:hidden block">
        <CardSectionResponsive />
      </div>
      <div className="md:block lg:block xl:hidden hidden">
        <CardSectionMedium />
      </div> */}
      <DownloadSection />
    </div>
  );
};

export default Landing;
