import React from "react";
import { GoDotFill } from "react-icons/go";

const FeeandRefundPolicy = () => {
  return (
    <div className="h-full w-full p-6 sm:p-24 bg-white flex flex-col gap-0 lg:gap-10">
      <div className="sm:h-[30vh] h-[40vh] w-full bg-white flex">
        <span className="flex justify-start my-auto text-3xl sm:text-5xl font-bold w-full text-left">
          Subscription Fee and Refund Policy
        </span>
      </div>
      <div className="flex flex-col gap-5 ">
        <span className="text-2xl font-bold">Subscription Fees</span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  SB Technologies (PVT) Ltd (“Company”, “our”, “us”) offers the
                  purchaser (“you”/ “your”) a free trial period of fourteen (14)
                  days to fully evaluate SkoolBus (“Licensed Application”)
                  before you make the decision to purchase the full version.
                  Once the trial period is completed, if you are interested in
                  continuing with the Licensed Application you can purchase the
                  same. If you have any questions prior to making the purchase,
                  you can contact our support team at support@skoolbus.app.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  You may add the number of children under your subscription at
                  any time during your billing cycle, and you will be charged
                  the Subscription Fee accordingly. In the event of removing the
                  number of children added, there shall be no refund for the
                  remainder of the respective billing period.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  Upon purchase you are required to pay a month-on-month basis
                  prepaid subscription fee (“Subscription Fee”) and will be
                  charged on a monthly basis.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  You are liable to pay all taxes, stamp duty and other
                  government charges which may apply to this policy or to the
                  purchase of the Licensed Application under it.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  Unless otherwise specified, Subscription Fee and other charges
                  paid using your credit or debit card are subject to merchant
                  fees.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  For single transactions, the Company shall charge the
                  Subscription Fees to your credit card once, while recurring
                  Subscription Fees will be charged in advance to your credit
                  card according to your billing cycle. Your billing cycle
                  starts on the date of your subscription and runs for thirty
                  (30) days.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  You agree to pay in respect of the Licensed Application, the
                  applicable recurring Subscription Fees as specified in the
                  subscription.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  Payment of the Subscription Fee must be made by way of direct
                  debit from a credit card and will be deducted as per your
                  payment authority. By using a credit card for the payment of
                  the Subscription Fees, you represent and warrant to the
                  Company that you are the authorized card holder and absolve
                  the Company of any liability for any charges arising from the
                  use of the Licensed Application.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  The Company reserves the right to amend its schedule of
                  Subscription Fee and/or change its billing methods. Should you
                  find any change or amendment unacceptable, you may opt to
                  cancel your subscription and such cancellation through the
                  billing page. Upon cancellation the Company shall discontinue
                  the Licensed Application from your use.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  Without limiting other remedies, the Company may suspend the
                  provision of maintenance support or other products or services
                  if any Subscription Fees are overdue.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  You may re-activate your subscription following payment of all
                  amounts due.
                </span>
              </div>
            </span>
          </div>
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  Please note that paid Subscription Fees are non-refundable
                  upon termination and or cancellation of the account.
                </span>
              </div>
            </span>
          </div>
        </span>
      </div>
    </div>
  );
};

export default FeeandRefundPolicy;
