import React from 'react'
import { MdRefresh } from 'react-icons/md';
import Tooltip from '../../Tooltip/Tooltip';
import { useDispatch } from 'react-redux';
import { clearMessages } from '../../../store/actions/ChatbotActions';

const HeaderChatbot = ({Header,onclickRefresh}) => {
  const dispatch = useDispatch();
  const handleRefreshClick = () => {
    dispatch(clearMessages());
  }
  return (
    <div className="flex justify-between  px-7 pt-5 py-2 items-center bg-[#EEE7F0] rounded-t-[40px]">
      <img src="./assets/icons/skoolbuslogo.svg" alt="logo" className="" />
      <h1 className="text-base font-bold">{Header}</h1>
      <div>
        <Tooltip children={"Refresh"} text={"Refresh"} width={"w-full"}>
          <MdRefresh className="w-6 h-6 hover:scale-95 duration-300 cursor-pointer" onClick={handleRefreshClick}/>
        </Tooltip>
      </div>
    </div>
  );
}

export default HeaderChatbot
