import React, { useEffect, useState } from "react";
import { ButtonPrimary } from "../components/buttons/Buttons";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { navlinks } from "../routes/routes";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { QRModal } from "../components/modal/Modal";

const Navbar = () => {
  const [showCloseIcon, setShowCloseIcon] = useState(false);
  const [scrolled, setScrolled] = useState(false); // New state for tracking scroll
  const location = useLocation();

  const toggleIcons = () => {
    setShowCloseIcon((prevState) => !prevState);
  };

  const [active, setActive] = useState(0);

  function handleSetActive(index) {
    setActive(index);
    console.log(index);
    setShowCloseIcon(false);
    //window.location.reload();
    window.scrollTo(0, 0);
    localStorage.setItem("activeNavIndex", index);
  }

  const menuVariants = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        opacity: { duration: 0.3 },
        y: { type: "spring", stiffness: 300, damping: 30 },
      },
    },
    closed: {
      opacity: 0,
      y: "-100%",
      transition: {
        opacity: { duration: 0.3 },
        y: { type: "spring", stiffness: 300, damping: 30 },
      },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    const handlePopstate = () => {
      const currentPath = location.pathname;

      const activeIndex = navlinks.findIndex(
        (link) => link.path === currentPath
      );

      setActive(activeIndex);
      localStorage.setItem("activeNavIndex", activeIndex);
    };

    const handleScroll = () => {
      if (window.scrollY > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("popstate", handlePopstate);
    window.addEventListener("scroll", handleScroll); // Add scroll event listener

    const storedActiveIndex = localStorage.getItem("activeNavIndex");
    if (storedActiveIndex !== null) {
      setActive(parseInt(storedActiveIndex, 10));
    } else {
      // Set the default active state to 0 if not stored in local storage
      setActive(0);
    }

    return () => {
      window.removeEventListener("popstate", handlePopstate);
      window.removeEventListener("scroll", handleScroll); // Remove scroll event listener on component unmount
    };
  }, [location.pathname]);

  return (
    <div
      className={`py-4 px-6 lg:px-24 fixed w-full z-40 ${
        scrolled ? "bg-cWhite opacity-1 shadow-md" : ""
      }`}
      style={{ transition: "background-color 0.1s ease" }}
    >
      <div className="flex flex-row items-center justify-between w-full">
        <Link to="/" onClick={() => handleSetActive(0)}>
          <div className="">
            <img
              src="/assets/icons/Logo.svg"
              alt="logo"
              className="object-contain"
            />
          </div>
        </Link>
        <div className="xl:block hidden">
          <div className="flex flex-row gap-16">
            <nav className=" flex  items-center text-lg flex-row justify-evenly gap-16 text-cBlack font-bold">
              {navlinks.map((link, index) => (
                <div
                  key={index}
                  onClick={() => handleSetActive(index)}
                  className="cursor-pointer"
                >
                  <Link
                    className="cursor-pointer hover:text-main duration-300 relative w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-main after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-left"
                    path={link.value}
                    to={link.path}
                  >
                    {link.title}
                  </Link>
                  {/* {active === index && (
                    <div className="bg-yellow-400 rounded-full hidden sm:block w-1 h-1 my-1 sm:mx-auto " />
                  )} */}
                </div>
              ))}
            </nav>
            <div className="flex flex-row gap-10 items-center ">
              <div className="hidden lg:block">
                <QRModal />
              </div>
            </div>
          </div>
        </div>
        <div className="xl:hidden block lg:block md:block sm:block">
          <div className="flex flex-row gap-3 items-start">
            <div onClick={toggleIcons}>
              {showCloseIcon ? (
                <AiOutlineClose
                  className="text-main cursor-pointer xl:hidden block"
                  size={25}
                />
              ) : (
                <AiOutlineMenu
                  className="text-main cursor-pointer xl:hidden block"
                  size={25}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showCloseIcon && (
        <motion.div
          className="relative h-full w-full bg-main py-4 mt-4 rounded-md flex flex-col justify-center items-center gap-9 z-10"
          initial="closed"
          animate="open"
          exit="closed"
          variants={menuVariants}
        >
          <motion.nav
            className=" flex  items-center text-base flex-col justify-evenly gap-9 text-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {navlinks.map((link, index) => (
              <motion.div
                key={index}
                onClick={() => handleSetActive(index)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link
                  className="cursor-pointer"
                  path={link.value}
                  to={link.path}
                >
                  {link.title}
                </Link>
              </motion.div>
            ))}
          </motion.nav>
          <div className="hidden xl:block">
            <ButtonPrimary text={"Download"} />
          </div>
          {/* <BiHelpCircle className="text-white cursor-pointer " size={25} /> */}
        </motion.div>
      )}
    </div>
  );
};

export default Navbar;
