import React, { useEffect, useState } from "react";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdMarkEmailUnread } from "react-icons/md";
import { ValidateEmail } from "../validation";
import { SendEmail, SubscribeEmail } from "../API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InlineError from "../validation/InlineError";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [send, setSend] = useState();
  const [isSent, setIsSent] = useState(false);

  useEffect(() => {
    ValidateEmail({ email, setEmailError });
    if (send) {
      setEmail("");
      setSend(false);
      setIsSent(true);
      setTimeout(() => setIsSent(false), 5000);
    }
  }, [email, send]);

  console.log(emailError);

  const submitHandler = (e) => {
    e.preventDefault();
    setButtonLoading(true);

    if (!email) {
      // Show error toast if email is empty
      toast.error("Please enter your email address");
      setButtonLoading(false);
      return;
    }
    
    if (!emailError) {
      console.log("Sending email");
      SubscribeEmail({ email, setSend }).then(() => {
        setButtonLoading(false);
        console.log(SendEmail);
        console.log("Email sent");
      });
      toast.success("Successfully subscribed to our newsletter !");
    }
    else {
      toast.error("Please enter a valid email address");
      setButtonLoading(false);
    }
    
  };
  function handleSetActive(index) {
    window.scrollTo(0, 0);
  }

  //  function handleSetActive(index) {
  //    window.scrollTo();
  //  }

 
  return (
    <div className="-mt-16 md:-mt-24 lg:-mt-16">
      <div className="sm:px-20 relative top-16 md:top-24 lg:top-16">
        <div className="bg-[#FCF6FF] rounded-3xl flex flex-col xl:flex-row justify-between xl:gap-[230px] gap-10 items-center w-full p-4 sm:p-8  shadow-2xl">
          <div className="flex flex-col lg:flex-row lg:items-center gap-8 w-full">
            <MdMarkEmailUnread className="text-[#45115F] w-8 h-8 mx-auto" />
            <div className="flex flex-col gap-0.5 w-full lg:-mt-0 -mt-1">
              <span className="text-lg sm:text-2xl font-semibold text-center lg:text-left">
                Subscribe to our newsletter
              </span>
              <span className="text-base font-medium text-black opacity-80 text-center lg:text-left">
                Subscribe for exclusive updates, exciting news, and special
                offers. Join for a vibrant inbox!
              </span>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <form
              onSubmit={submitHandler}
              className="flex flex-col sm:flex-row w-full border border-solid border-[#45115f4c] rounded-md p-2  justify-center items-center"
            >
              <div className="flex flex-col w-full">
                <input
                  type="text"
                  placeholder="Enter your email address"
                  className="bg-transparent w-full outline-none text-lg font-medium duration-300 ease-in-out placeholder:text-center"
                  required
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              <div className="hidden sm:block">
                <button
                  type="submit"
                  disabled={buttonLoading && true}
                  className="flex px-5 sm:px-10 py-1 sm:py-3 bg-main rounded-md text-white text-base font-600 hover:shadow-md hover:bg-[#331940] hover-text-black transition duration-300 ease-in-out"
                >
                  {buttonLoading ? "Loading..." : "Subscribe"}
                </button>
              </div>
              <ToastContainer />
            </form>
            <span className="block sm:hidden text-center">
              {" "}
              {emailError && <InlineError error={emailError} />}
            </span>
            <div className="block sm:hidden mt-4 mx-auto">
              <button
                type="submit"
                disabled={buttonLoading && true}
                className="flex px-5 sm:px-10 py-1 sm:py-3 bg-main rounded-md text-white text-base font-600 hover:shadow-md hover:bg-[#331940] hover-text-black transition duration-300 ease-in-out"
              >
                {buttonLoading ? "Loading..." : "Subscribe"}
              </button>
            </div>
            <span className="hidden sm:block">
              {emailError && <InlineError error={emailError} />}
            </span>
          </div>
        </div>
      </div>
      <div className="footerSectionBg p-8 sm:px-20 pt-32 pb-10 w-full">
        <div className="flex flex-col lg:flex-row justify-between w-full lg:gap-80 gap-10">
          <div className="flex flex-col gap-6">
            <div className="flex gap-3 items-center">
              <div className="w-10 h-10">
                <img
                  src="/assets/icons/Icon_logo.png"
                  alt="logo"
                  className="w-full"
                />
              </div>
              <span className="text-white font-semibold text-2xl">
                SkoolBus
              </span>
            </div>
            <div className="text-white font-medium text-base sm:text-lg w-full">
              Your child's safety is in the palm of your hand. Real-time
              tracking, instant alerts—seamless peace of mind for parents.
            </div>

            <div className="flex justify-center sm:justify-start gap-6 mt-4 lg:mt-0 items-center">
              <a
                href="https://apps.apple.com/lk/app/skoolbus/id6465951976"
                tabIndex="0"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-36 hover:scale-95 duration-500"
                  src="https://imgs.search.brave.com/PhFkigbrIwPnUTaDWMBqhaVOfCkbOMWS6R0MzoQ5Fwg/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9hc3Nl/dHMuc3RpY2twbmcu/Y29tL2ltYWdlcy81/YTkwMmRiOTdmOTY5/NTFjODI5MjI4NzQu/cG5n"
                  alt="bn45"
                />
              </a>
              <a
                href="https://play.google.com/store/search?q=SkoolBus&c=apps&hl=en&gl=US"
                tabIndex="0"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-40 hover:scale-95 duration-500"
                  src="https://www.freepnglogos.com/uploads/play-store-logo-png/play-store-logo-nisi-filters-australia-11.png"
                  alt="bn45"
                />
              </a>
            </div>
          </div>
          <div className="text-white flex flex-wrap lg:flex-row lg:w-full  gap-10 items-start justify-end lg:justify-end ">
            <div className="flex flex-col  gap-6 ">
              <div className="text-lg sm:text-2xl font-semibold">Company</div>
              <div className="flex flex-col gap-4 text-base font-normal">
                <Link to={"/about"} onClick={handleSetActive}>
                  About Us
                </Link>
                <Link to="/about#our-team">Our Team</Link>
                {/* <Link to={"/faq"} onClick={handleSetActive}>
                  FAQs
                </Link> */}
              </div>
            </div>
            <div className="flex flex-col gap-6 ">
              <div className=" font-semibold w-full text-lg sm:text-2xl">
                Product
              </div>
              <div className="flex flex-col gap-4 text-base font-normal w-full">
                <Link
                  to={"/faq"}
                  onClick={handleSetActive}
                  className="cursor-pointer"
                >
                  FAQs
                </Link>
                <Link
                  to={"/contact"}
                  onClick={handleSetActive}
                  className="cursor-pointer"
                >
                  Support
                </Link>
                <Link to={"/get-started"} className="w-full cursor-pointer">
                  Get started
                </Link>
                {/* <span>Track now</span> */}
              </div>
            </div>
            {/* <div className="flex flex-col gap-6">
              <div className="font-semibold text-lg sm:text-2xl">Driver</div>
              <div className="flex flex-col gap-4 text-base font-normal">
                <Link to={"/about"} onClick={handleSetActive}>
                  About Us
                </Link>
                <Link to={"/contact"} onClick={handleSetActive}>
                  Support
                </Link>
                <Link to={"/faq"} onClick={handleSetActive}>
                  FAQs
                </Link>
              </div>
            </div> */}
          </div>
        </div>
        <div className="h-px w-full bg-[#ffffff66] my-10" />
        <div className="w-full flex flex-col sm:flex-row items-center gap-10 justify-between">
          <div className="text-white ">
            <span className="text-xs font-normal text-center sm:text-left">
              Copyright @ 2023.SB Technologies | All rights reserved.
            </span>
            <div className="flex gap-2 text-xs font-normal justify-center sm:justify-start">
              <Link to="/privacy-policy" onClick={handleSetActive}>
                <span>Privacy Policy</span>
              </Link>
              <span>|</span>
              <Link to="/terms-and-conditions" onClick={handleSetActive}>
                <span>Terms and Conditions</span>
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-4 text-white">
            <div className="flex flex-row gap-4">
              <a
                href="https://www.facebook.com/profile.php?id=100093257525503&mibextid=LQQJ4d"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF className="text-xl" />
              </a>
              <a
                href="https://instagram.com/skoolbus_app?igshid=OGQ5ZDc2ODk2ZA=="
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="text-xl" />
              </a>
              <a
                href="https://www.linkedin.com/company/skoolbus/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin className="text-xl" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
