import React, { useState } from "react";
import HeaderChatbot from "../components/HeaderChatbot";
import FooterChatbot from "../components/FooterChatbot";
import * as chatbotActions from "../../../store/actions/ChatbotActions";
import { useDispatch } from "react-redux";
import ChatbotDefualtQuestionButton from "../components/ChatbotDefualtQuestionButton";
import ChatbotMessages from "../components/ChatbotMessages";

const ChatbotLanding = () => {
  const [query, setQuery] = useState("");
  const [showDefaultButton, setShowDefaultButton] = useState(true);
  const [questionText, setQuestionText] = useState("");

  const dispatch = useDispatch();
  const handleUserQuery = async () => {
    if (query === "") {
      alert("Please enter a valid input");
      return;
    }
    const data = {
      text: query,
    };
    dispatch(chatbotActions.textQueryAction(data));
    setQuery("");
    setShowDefaultButton(false);
    setQuestionText(query);
  };

  const handleDefaultQuestionClick = (question) => {
    console.log("question", question);
    dispatch(chatbotActions.textQueryAction({ text: question }));
    setShowDefaultButton(false);
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleUserQuery();
    }
  };

  return (
    <div className="flex flex-col justify-betweenn w-full h-full rounded-[40px] overflow-x-hidden shadow-lg">
      <HeaderChatbot Header={"SkoolBus"} />
    
        {showDefaultButton ? (
            <div className="w-full h-full bg-[#eee7f0] overflow-y-scroll chatbot-scrollbar">
          <div className="w-full h-full flex flex-col gap-2 justify-evenly items-center px-4">
            <ChatbotDefualtQuestionButton
              Question={"What is SkoolBus?"}
              onClick={() => handleDefaultQuestionClick("What is SkoolBus?")}
            />
            <ChatbotDefualtQuestionButton
              Question={"How can SkoolBus help me as a Parent?"}
              onClick={() =>
                handleDefaultQuestionClick(
                  "How can SkoolBus help me as a Parent?"
                )
              }
            />
            <ChatbotDefualtQuestionButton
              Question={"How safe is my child with SkoolBus?"}
              onClick={() =>
                handleDefaultQuestionClick(
                  "How safe is my child with SkoolBus?"
                )
              }
            />
          </div>
          
      </div>
        ) : (
          <ChatbotMessages userMessage={questionText} />
        )}
      <FooterChatbot
        onClick={handleUserQuery}
        onKeyUp={handleKeyUp}
        setQuery={setQuery}
        value={query}
      />
    </div>
  );
};

export default ChatbotLanding;
