import React from "react";
import {
  AsADriver,
  AsAParent,
  AssessmentAndProtection,
  AssessmentAndProtectionSubPoints,
  DiscloseOfInformation,
  PurposeOfThisPolicy,
  Rights,
  StorageAndRetention,
  UsageOfInformation,
} from "../constants/Privacy-Policy";
import { GoDotFill } from "react-icons/go";
import { PiDotOutlineDuotone } from "react-icons/pi";

const PrivacyPolicy = () => {
  return (
    <div className="h-full w-full p-6 sm:p-24 bg-white flex flex-col gap-0 lg:gap-10">
      <div className="sm:h-[30vh] h-[40vh] w-full bg-white flex">
        <span className="flex justify-start my-auto text-3xl sm:text-5xl font-bold w-full text-left">
          Privacy and Data <br/>Protection Policy
        </span>
      </div>
      <div className="flex flex-col gap-5 ">
        <span className="text-2xl font-bold">
          1. Who are we and the purpose of this policy
        </span>
        {PurposeOfThisPolicy.map((item) => {
          return (
            <span className="text-lg font-bold text-[#666666]" key={item.id}>
              <div className="flex gap-2 ">
                <div>
                  <GoDotFill className="text-[#666666] " />
                </div>
                <span className="-mt-1">{item.point}</span>
              </div>
            </span>
          );
        })}
      </div>
      <div className="flex flex-col gap-5">
        <span className="text-2xl font-bold">
          2. What information do we collect and how we collect it
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex gap-2 ">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  We collect and maintain personal information about you from
                  many sources to understand and meet your needs, facilitate
                  your request, and provide our services. The personal data we
                  collect may include but is not limited to,
                </span>
              </div>
            </span>
          </div>
          <div className="text-base text-black pt-4 font-bold px-8">
            If Parent/Guardian;
          </div>
          {AsAParent.map((item, key) => {
            return (
              <div className="flex flex-colsm:px-12 pt-4">
                <span className="flex gap-2">
                  <div>
                    <PiDotOutlineDuotone />
                  </div>
                  <span className="-mt-1">{item.point}</span>
                </span>
              </div>
            );
          })}
          <div className="text-base text-black pt-4 font-bold px-8">
            If Driver;
          </div>
          {AsADriver.map((item, key) => {
            return (
              <div className="flex flex-col sm:px-12 pt-4">
                <span className="flex gap-2">
                  <div>
                    <PiDotOutlineDuotone />
                  </div>
                  <span className="-mt-1">{item.point}</span>
                </span>
              </div>
            );
          })}
          <div className="flex gap-2  py-4">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666]">
              <div className="flex gap-2 -mt-1">
                <span>
                  We collect most of the personal data of the parent/guardian
                  and the primary driver/ secondary driver to enhance your
                  child’s safety and provide the parent/guardian with real-time
                  location information of your child during their school
                  journeys.
                </span>
              </div>
            </span>
          </div>
          <div className="flex gap-2 py-4">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666] -mt-1">
              <div className="flex gap-2 items-center">
                <span>
                  To the extent that the personal information we collect
                  constitutes sensitive personal information under applicable
                  law, the SkoolBus Application will collect and process this
                  sensitive personal information within the limits provided by
                  applicable law, and only after establishing reasonable
                  security safeguards for such sensitive personal information.
                  Where required by law, the SkoolBus Application will seek your
                  consent before processing sensitive personal information.
                </span>
              </div>
            </span>
          </div>
          <div className="flex gap-2  py-4">
            <div>
              <GoDotFill className="text-[#666666] " />
            </div>
            <span className="text-lg font-bold text-[#666666]">
              <div className="flex gap-2 items-center -mt-1">
                <span>
                  We will not share any data collected with any non-authorized
                  third parties.
                </span>
              </div>
            </span>
          </div>
        </span>
      </div>
      <div className="flex flex-col gap-5 ">
        <span className="text-2xl font-bold">
          3. How We Use Your Information
        </span>
        <span className="text-lg font-bold">
          The Company collects personal data for some or all of the following
          reasons.
        </span>
        {UsageOfInformation.map((item) => {
          return (
            <span className="text-lg font-bold text-[#666666]" key={item.id}>
              <div className="flex gap-2">
                <div>
                  <GoDotFill className="text-[#666666] " />
                </div>
                <span className="-mt-1">{item.point}</span>
              </div>
            </span>
          );
        })}
      </div>
      <div className="flex flex-col gap-5 ">
        <span className="text-2xl font-bold">
          4. How we disclose your information
        </span>
        {DiscloseOfInformation.map((item) => {
          return (
            <span className="text-lg font-bold text-[#666666]" key={item.id}>
              <div className="flex gap-2">
                <div>
                  <GoDotFill className="text-[#666666] " />
                </div>
                <span className="-mt-1">{item.point}</span>
              </div>
            </span>
          );
        })}
      </div>
      <div className="flex flex-col gap-5 ">
        <span className="text-2xl font-bold">5. Storage and Retention</span>
        <span className="text-lg font-bold text-center sm:text-left">
          We may keep information and content in our systems, backup files, and
          archives as follows;
        </span>
        <span className="text-lg font-bold text-[#666666]">
          <div className="flex flex-col gap-2 ">
            <div className="flex gap-2  text-left w-full">
              <div>
                <GoDotFill className="text-[#666666] " />
              </div>
              <span className="text-lg font-bold -mt-1">
                Utilized encryption for data at rest and in transit.
              </span>
            </div>
            <div className="flex flex-col gap-4 text-left w-full px-12">
              <span>
                i. Strong hashing for sensitive information in the database
                tables.
              </span>
              <span>
                ii. Server-side encryption by default in the database.
              </span>
            </div>
          </div>
        </span>
        {StorageAndRetention.map((item) => {
          return (
            <span className="text-lg font-bold text-[#666666]" key={item.id}>
              <div className="flex gap-2">
                <div>
                  <GoDotFill className="text-[#666666] " />
                </div>
                <span className="-mt-1">{item.point}</span>
              </div>
            </span>
          );
        })}
        <span className="text-lg font-bold text-center sm:text-left">
          Your personal data will be retained as long as necessary to provide
          you with the services requested. When we no longer need to use your
          personal data to comply with business requirements, we will remove it
          from our systems and records and/or take steps to properly anonymize
          it so that you can no longer be identified from it, unless we need to
          keep your personal data, including if we need to keep your personal
          data to comply with legal or regulatory obligations to which we are
          subject.
        </span>
      </div>
      <div className="flex flex-col gap-5 ">
        <span className="text-2xl font-bold">
          6. Threat Assessment and Protection
        </span>
        <span className="text-lg font-bold">
          We have standardized enterprise-wide analysis of software-related
          threats within the organization and have prioritized proactive
          improvement of threat coverage throughout the organization to ensure
          that there are no leaks of the data we collect.
        </span>
        <span className="text-lg font-bold">
          We are committed to ensuring that your information is secure. In order
          to prevent unauthorized access or disclosure we have put in place the
          following suitable physical, electronic, and managerial procedures to
          secure the information.
        </span>
        {AssessmentAndProtection.map((item) => {
          return (
            <span className="text-lg font-bold text-[#666666]" key={item.id}>
              <div className="flex gap-2 ">
                <div>
                  <GoDotFill className="text-[#666666] " />
                </div>
                <span className="-mt-1">{item.point}</span>
              </div>
            </span>
          );
        })}
        <div className="px-8 flex flex-col gap-1">
          {AssessmentAndProtectionSubPoints.map((item) => {
            return (
              <span className="text-lg font-bold text-[#777777]" key={item.id}>
                <div className="flex gap-2 ">
                  <div>
                    <GoDotFill className="text-[#777777] " />
                  </div>
                  <span className="-mt-1">{item.point}</span>
                </div>
              </span>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-5 w-full">
        <span className="text-2xl font-bold">7. Your Rights</span>
        {Rights.map((item) => {
          return (
            <div className="flex w-full gap-2">
              <GoDotFill className="text-[#666666] " />
              <div className="text-lg font-bold w-full -mt-1">
                <span className="text-[#444444]"> {item.MainPoint}</span> -{" "}
                <span className="text-[#555555]">{item.point}</span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-5 w-full">
        <span className="text-2xl font-bold">8. How to Contact Us</span>
        <div className="flex flex-col gap-3 px-8">
          <span className="text-lg font-bold">
            If you have any questions or concerns regarding the Privacy Policy,
            please feel free to contact us at the following email or telephone
            number
          </span>
          <span className="text-lg font-bold">Email: privacy@skoolbus.app</span>
          <span className="text-lg font-bold">Phone: (+94) 77 547 4343</span>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
