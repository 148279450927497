import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import AOS from "aos";
import "aos/dist/aos.css";

const ChatbotSplashScreen = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    AOS.init();
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 10); // Increase progress by 10% every second
      } else {
        clearInterval(interval);
      }
    }, 300); // Update progress every 300 milliseconds

    // Simulate loading completion after 3 seconds
    setTimeout(() => {
      clearInterval(interval);
      setProgress(100);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [progress]);

  return (
    <div className="w-full h-full bg-[#EEE7F0] chatbot-bg flex flex-col justify-end">
      {/* Updated class and added flex properties */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full flex justify-center items-center"
      >
        <motion.img
          src="/assets/icons/Logo.svg"
          alt="chatbot"
          className="object-cover"
        />
      </motion.div>
      <div data-aos="fade-up" data-aos-duration="500">
        <img src="./assets/icons/chatbot-bg-lb.svg" alt="logo" className="" />
      </div>
      <div className="px-7 rounded-l-2xl flex absolute bottom-0">
        {/* Moved the progress bar here */}
        <div
          className="h-1 bg-main rounded-2xl  "
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default ChatbotSplashScreen;
