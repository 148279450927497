import React from "react";
import { RiSendPlane2Fill } from "react-icons/ri";

const FooterChatbot = ({ value, setQuery, onKeyUp, onClick }) => {
  return (
    <div className="px-7 py-5 bg-[#EEE7F0] w-full flex rounded-b-[40px] gap-6 items-center z-50">
      <input
        type="text"
        placeholder="Type your message here"
        className="p-2 rounded-xl w-full outline-none"
        value={value}
        onChange={(e) => setQuery(e.target.value)}
        onKeyUp={onKeyUp}
      />
      <RiSendPlane2Fill
        className="w-8 h-8 text-main hover:scale-90 z-40"
        onClick={onClick}
      />
    </div>
  );
};

export default FooterChatbot;
