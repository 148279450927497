import React, { useState } from "react";

const Tooltip = ({ text, children, width }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative inline-block"
    >
      {children}
      {showTooltip && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "120%", // Display above the trigger element
            left: "50%",
            transform: "translateX(-50%)",
            color: "#1d1d1f",
            padding: "10px",
            borderRadius: "4px",
            zIndex: 1, // Ensure tooltip is above the trigger element
          }}
          className={`${width}text-center text-sm bg-[#F8EBC6] font-500`}
        >
          {text}
          <div
            className="rotate-180"
            style={{
              position: "absolute",
              content: "",
              bottom: "100%", // Positioned at the bottom of the tooltip
              left: "50%",
              marginLeft: "-5px", // Adjust this value to control arrow position
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderTop: "5px solid #F8EBC6",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Tooltip;
